<template>
  <v-main class="bg-lite">
    <RouterView />
  </v-main>
</template>

<script>
export default {
  name: 'SlimLayout',
};
</script>

<style></style>
